<template>
  <div
    style="height: 100%; width: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0"
  >
    <div class="k-0g35po">
      <div
        :class="
          collapse ? 'k-0g35po-title k-0g35po-collapse' : 'k-0g35po-title'
        "
      >
        {{ appName }}
      </div>
      <div class="k-0g35po-conent">
        <div class="k-0g35po-continer">
          <headerBar />
          <mainContent />
        </div>
      </div>
    </div>
    <navMenu />
  </div>
</template>
<script>
import navMenu from '@/components/navMenu'
import mainContent from '@/components/mainContent'
import headerBar from '@/components/headerBar'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      appName:
        process.env.NODE_ENV == 'development'
          ? '微社区管理系统(测试服)'
          : '微社区管理系统(线上)',
    }
  },
  components: {
    navMenu,
    mainContent,
    headerBar,
  },
  computed: {
    ...mapState('navMenu', ['collapse']),
  },
}
</script>
<style lang="less" src="./index.less"></style>
