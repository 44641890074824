<template>
  <div class="k-kt6p6u">
    <!-- 先隐藏 -->
    <el-tabs
      v-model="bars.tabsValue"
      type="border-card"
      closable
      @tab-click="handleTabsClick"
      @tab-remove="removeTab"
    >
      <el-tab-pane
        :key="item.name"
        v-for="item in bars.tabsList"
        :label="item.title"
        :name="item.name"
      >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import store from "@/store";
import { Tabs, TabPane } from "element-ui";
import Vue from "vue";
Vue.use(Tabs);
Vue.use(TabPane);
import { mapActions, mapState, mapMutations } from "vuex";
import { setItem, getItem } from "@/utils/storage";
export default {
  data() {
    return {};
  },
  
  watch: {
    $route: {
      handler: function(item) {
        //如果没有权限则请求login
        const permSign = store.state.permSign.permSign;
        if (permSign.length == 0) {
          this.login();
        }

        let sessionBar = getItem("bars");
        //如果有tab缓存并且id为0(代表页面是否重置)才会取缓存的tab,同时调用login防止按钮权限丢失
        if (this.bars.id == 0 && sessionBar) {
          this.setBars(sessionBar);
        }

        const tabs = this.bars.tabsList;
        let isAdd = tabs.find((arg) => {
          //是否已经存在与当前路由对应的tab。
          //如果存在，则将该tab的name设置为当前选中的tab，并直接返回
          return arg.title === item.name;
        });
        //存在与当前路由对应的tab
        if (isAdd) {
          this.bars.tabsValue = isAdd.name;
          // let index = tabs.indexOf(isAdd);
          // if (index >= 0 && index < tabs.length - 1) {
          //   tabs.splice(index, 1);
          //   if (isAdd.path == '/') {
          //     tabs.unshift(isAdd);
          //   } else {
          //     tabs.push(isAdd);
          //   }
          // }
          return;
        }

        // const currentTab = tabs.find((r) => {
        //   return r.name == this.bars.tabsValue;
        // });

        this.addTabs(item);
        // if (!isAdd) {
        //   isAdd = tabs.find((arg) => {
        //     return arg.title === item.name;
        //   });
        //
        //   if (currentTab) {
        //     let indexCurrent = tabs.indexOf(currentTab);
        //     if (indexCurrent >= 0 && indexCurrent < tabs.length - 1) {
        //       tabs.splice(indexCurrent, 0, isAdd);
        //       let index = tabs.indexOf(isAdd);
        //       tabs.splice(index, 1);
        //     }
        //   }
        // }

        setItem("bars", this.bars);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("tabs", ["bars"]),
  },
  methods: {
    ...mapMutations("tabs", ["setBars"]),
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
    ]),
    ...mapActions("permSign", ["login"]),
  },
};
</script>
<style src="./index.less" lang="less"></style>
