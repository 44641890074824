<template>
  <div>
    <div class="k-j2nnc8">
      <div class="k-j2nnc8-cos" @click="setCollapse(!collapse)">
        <i :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>
      <div style="display: flex; flex-direction: row">
        <div
          class="k-j2nnc8-loginout"
          @click="changePwd"
          style="cursor: pointer"
        >
          修改密码
        </div>
        <div class="k-j2nnc8-loginout" @click="loginOut">退出</div>
      </div>
    </div>

    <el-dialog :title="'修改密码'" :visible.sync="pwdVisible">
      <el-form
        ref="changePwdForm"
        :rules="changePwdRule"
        :model="changePwdForm"
        label-width="80px"
      >
        <el-form-item label="旧密码" prop="pass">
          <el-input
            v-model="changePwdForm.pass"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input
            v-model="changePwdForm.newPass"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            v-model="changePwdForm.checkPass"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmPwd">确定</el-button>
          <el-button @click="pwdVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { MessageBox } from 'element-ui'
import { mapActions, mapMutations, mapState } from 'vuex'
import Cookie from 'js-cookie'
export default {
  computed: {
    ...mapState('navMenu', ['collapse']),
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.changePwdForm.checkPass !== '') {
          this.$refs.changePwdForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.changePwdForm.newPass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      pwdVisible: false,
      changePwdForm: {
        checkPass: '',
        pass: '',
        newPass: '',
      },
      changePwdRule: {
        pass: [
          { required: true, message: '请输入旧密码', trigger: 'change' },
          {
            min: 1,
            max: 16,
            message: '长度在 1 到 16 个字符',
            trigger: 'change',
          },
          { validator: validatePass },
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          {
            min: 1,
            max: 16,
            message: '长度在 1 到 16 个字符',
            trigger: 'change',
          },
          { validator: validatePass },
        ],
        checkPass: [
          { required: true, message: '请输入确认密码', trigger: 'change' },
          {
            min: 1,
            max: 16,
            message: '长度在 1 到 16 个字符',
            trigger: 'change',
          },
          { validator: validatePass2 },
        ],
      },
    }
  },
  methods: {
    ...mapMutations('navMenu', ['setCollapse']),
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'clearTab',
    ]),
    changePwd() {
      this.pwdVisible = true
      this.changePwdForm = {
        checkPass: '',
        pass: '',
        newPass: '',
      }
    },
    async confirmPwd() {
      if (
        !this.changePwdForm.pass ||
        !this.changePwdForm.pass.length ||
        !this.changePwdForm.newPass ||
        !this.changePwdForm.newPass.length ||
        !this.changePwdForm.checkPass ||
        this.changePwdForm.newPass != this.changePwdForm.checkPass
      ) {
        return
      }

      const { errorCode } = await this.$http.changePwd({
        pass: this.changePwdForm.pass,
        newPass: this.changePwdForm.newPass,
      })
      if (errorCode === '0000') {
        this.$message({
          type: 'success',
          message: '修改成功',
        })

        const user = Cookie.get('user')
        if (user) {
          const loginForm = {
            username: user.split(',')[0],
            password: user.split(',')[1],
          }

          Cookie.set(
            'user',
            `${loginForm.username},${this.changePwdForm.newPass}`
          )
        }

        this.$refs['changePwdForm'].resetFields()
        this.pwdVisible = false
      }
    },
    async loginOut() {
      try {
        await MessageBox.confirm('此操作将退出系统, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        Cookies.remove('token')
        sessionStorage.clear()
        this.clearTab()
        this.$router.push('/login')
        this.$store.commit('navMenu/setNavTree', false)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
<style src="./index.less" lang="less"></style>
