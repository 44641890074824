<template>
    <div style="position: absolute; left: 0; top: 50px; right: 0; bottom: 0; display: flex; flex-direction: column; align-content: flex-start">
        <tabBar />
        <div class="k-owao1y" style="height: 100%; overflow: auto; border-top: 1px solid #E4E7ED; background-color: #fcfcfc">
          <!-- 根据$route.meta.keepAlive的值来决定是否缓存并渲染子组件。
          如果keepAlive为true，则缓存子组件，否则不缓存。 -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"/>
        </div>
    </div>
</template>
<script>
import tabBar from './component/tabs/tabBar.vue'
export default {
    components:{
        tabBar
    }
}
</script>
<style lang="less">
.k-owao1y{
    padding: 10px;
}
</style>
